/* import __COLOCATED_TEMPLATE__ from './go-live.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type IntlService from 'embercom/services/intl';
import type RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import type { Step } from 'embercom/components/standalone/common/stepper-item';

export interface Args {}

export default class GoLive extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  get config() {
    return this.finStandaloneService.intercomMessengerConfig;
  }

  get intercomMessengerConfig() {
    return this.finStandaloneService.intercomMessengerConfig;
  }

  get workflowId() {
    return this.intercomMessengerConfig?.workflowRulesetId;
  }

  get isFinEnabled() {
    return this.intercomMessengerConfig?.isMessengerEnabled;
  }

  get steps(): Array<Step> {
    if (this.appService.app.hasStandalonePlatform('zendesk')) {
      return this.zendeskSteps.concat(this.sharedSteps);
    }

    return this.sharedSteps;
  }

  get sharedSteps(): Array<Step> {
    return [
      {
        id: 'training',
        title: this.intl.t('standalone.intercom-messenger.deploy.go-live.steps.training.title'),
        button: {
          icon: 'knowledge',
          text: this.intl.t('standalone.intercom-messenger.deploy.go-live.steps.training.button'),
          href: this.router.urlFor('apps.app.knowledge-hub.overview'),
        },
      },
      {
        id: 'workflow',
        title: this.intl.t('standalone.intercom-messenger.deploy.go-live.steps.workflow.title'),
        button: {
          icon: 'workflows',
          text: this.intl.t('standalone.intercom-messenger.deploy.go-live.steps.workflow.button'),
          href: this.router.urlFor(
            'apps.app.standalone.deploy.intercom-messenger.workflow.show',
            this.workflowId,
            {
              queryParams: { returnPath: this.router.currentRouteName },
            },
          ),
        },
      },
      {
        id: 'preferences',
        title: this.intl.t('standalone.intercom-messenger.deploy.go-live.steps.preferences.title'),
        button: {
          icon: 'edit',
          text: this.intl.t(
            'standalone.intercom-messenger.deploy.go-live.steps.preferences.button',
          ),
          href: this.router.urlFor('apps.app.standalone.deploy.intercom-messenger.integrate', {
            queryParams: {
              section: 'fin-identity',
            },
          }),
        },
      },
      {
        id: 'messenger',
        title: this.intl.t('standalone.intercom-messenger.deploy.go-live.steps.messenger.title'),
        button: {
          icon: 'messenger',
          text: this.intl.t('standalone.intercom-messenger.deploy.go-live.steps.messenger.button'),
          href: this.router.urlFor('apps.app.settings.channels.messenger.general'),
        },
      },
    ];
  }

  get zendeskSteps(): Array<Step> {
    if (this.finStandaloneService.zendeskConfig?.sunshineIntegration.isSunshineConnected) {
      return [
        {
          id: 'zendesk',
          title: this.intl.t('standalone.intercom-messenger.deploy.go-live.steps.zendesk.title'),
          description: this.intl.t(
            'standalone.intercom-messenger.deploy.go-live.steps.zendesk.description',
          ),
        },
      ];
    } else {
      return [];
    }
  }

  @action
  async activate() {
    await this.finStandaloneService.intercomMessengerConfig.activate();
  }

  @action
  async deactivate() {
    await this.finStandaloneService.intercomMessengerConfig.deactivate();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Deploy::GoLive': typeof GoLive;
    'standalone/intercom-messenger/deploy/go-live': typeof GoLive;
  }
}
