/* import __COLOCATED_TEMPLATE__ from './go-live.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import type { Step } from 'embercom/components/standalone/common/stepper-item';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type RouterService from '@ember/routing/router-service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import { action } from '@ember/object';

interface Args {}

export default class StandaloneZendeskDeploySunshineTabsGoLive extends Component<Args> {
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: {
    notifyConfirmation: (message: string, duration: number, key: string) => void;
    notifyResponseError: (
      e: Error,
      messages: Record<string, string>,
      options: { responseProvidedErrors: number[] },
    ) => void;
  };

  get isKnowledgeCountPositive() {
    let knowledgeSourceSummary = this.finStandaloneService.knowledgeSourceSummary;
    return Object.values(knowledgeSourceSummary).some((summary) => summary.total_count > 0);
  }

  get steps(): Array<Step> {
    return [
      {
        id: 'messaging-channels',
        title: this.intl.t(
          'standalone.zendesk.deploy.sunshine.tabs.go-live.messaging-channels-description',
        ),
        button: {
          icon: 'zendesk',
          text: this.intl.t('standalone.zendesk.deploy.sunshine.tabs.go-live.messaging-channels'),
          href: this.router.urlFor('apps.app.standalone.deploy.zendesk.messaging.integrate', {
            queryParams: {
              section: 'sunshine-channels',
            },
          }),
        },
      },
      {
        id: 'training',
        title: this.intl.t('standalone.zendesk.deploy.sunshine.tabs.go-live.training-description'),
        button: {
          icon: 'knowledge',
          text: this.intl.t('standalone.zendesk.deploy.sunshine.tabs.go-live.training'),
          href: this.router.urlFor('apps.app.knowledge-hub.overview'),
        },
      },
      {
        id: 'workflow',
        title: this.intl.t('standalone.zendesk.deploy.sunshine.tabs.go-live.workflow-description'),
        button: {
          icon: 'workflows',
          text: this.intl.t('standalone.zendesk.deploy.sunshine.tabs.go-live.workflow'),
          href: this.router.urlFor(
            'apps.app.standalone.deploy.zendesk.messaging.workflow.show',
            this.zendeskConfig.sunshineIntegration.workflowRulesetId,
            {
              queryParams: {
                returnPath: this.router.currentRouteName,
              },
            },
          ),
        },
      },
      {
        id: 'identity',
        title: this.intl.t('standalone.zendesk.deploy.sunshine.tabs.go-live.identity-description'),
        button: {
          icon: 'edit',
          text: this.intl.t('standalone.zendesk.deploy.sunshine.tabs.go-live.identity'),
          href: this.router.urlFor('apps.app.standalone.deploy.zendesk.messaging.integrate', {
            queryParams: {
              section: 'zendesk-setup-fin-messenger-identity',
            },
          }),
        },
      },
    ];
  }

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig!;
  }

  get sunshineConfig() {
    return this.zendeskConfig.sunshineIntegration;
  }

  get isSunshineEnabled() {
    return this.sunshineConfig.isEnabledForSunshineMessenger;
  }

  get hasSunshineChannels() {
    return this.sunshineConfig.supportedChannelIntegrationIds.length > 0;
  }

  @action
  async activate() {
    await this.zendeskConfig.activateForMessenger();
  }

  @action
  async deactivate() {
    await this.zendeskConfig.deactivateForMessenger();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Sunshine::Tabs::GoLive': typeof StandaloneZendeskDeploySunshineTabsGoLive;
    'standalone/zendesk/deploy/sunshine/tabs/go-live': typeof StandaloneZendeskDeploySunshineTabsGoLive;
  }
}
