/* import __COLOCATED_TEMPLATE__ from './activation.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import ENV from 'embercom/config/environment';
import { RESPONSE_PROVIDED_ERRORS } from 'embercom/objects/standalone/constants';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type ThemeService from 'embercom/services/theme-service';

export interface Args {
  activate: () => Promise<void>;
  deactivate: () => Promise<void>;
  isActivated: boolean;
  isNotConfigured: boolean;
  isDeactivated: boolean;
  titles: {
    notConfigured: string;
    ready: string;
    live: string;
  };
  notifications: {
    activateSuccess: string;
    activateError: string;
    deactivateSuccess: string;
    deactivateError: string;
  };
}

interface NotificationsService {
  notify: (message: string, duration: number, key: string) => void;
  notifyConfirmation: (message: string, duration: number, key: string) => void;
  notifyResponseError: (
    e: Error,
    messages: { default: string },
    options: { responseProvidedErrors: number[] },
  ) => void;
}

export default class StandaloneDeployGoLiveActivation extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: NotificationsService;
  @service declare themeService: ThemeService;

  @tracked isSaving = false;

  get background() {
    let strength = this.args.isActivated ? 'strong' : 'weak';
    let mode = this.themeService.darkModeEnabled ? 'dark' : 'light';
    return `standalone__${strength}-glow-${mode}-mode-bg`;
  }

  @action async activateFin() {
    this.isSaving = true;
    try {
      await this.args.activate();
      this.notifyConfirmation(this.args.notifications.activateSuccess);
    } catch (e) {
      this.handleError(e, this.args.notifications.activateError);
    }
    this.isSaving = false;
  }

  @action async deactivateFin() {
    this.isSaving = true;
    try {
      await this.args.deactivate();
      this.notifyInfo(this.args.notifications.deactivateSuccess);
    } catch (e) {
      this.handleError(e, this.args.notifications.deactivateError);
    }
    this.isSaving = false;
  }

  handleError(e: Error & { jqXHR?: $TSFixMe }, defaultMessage: string) {
    console.error(e.message || e.jqXHR);
    this.notificationsService.notifyResponseError(
      e,
      { default: defaultMessage },
      { responseProvidedErrors: RESPONSE_PROVIDED_ERRORS },
    );
  }

  private notifyConfirmation(message: string) {
    this.notificationsService.notifyConfirmation(
      message,
      ENV.APP._2000MS,
      'fin-standalone-activation',
    );
  }

  private notifyInfo(message: string) {
    this.notificationsService.notify(message, ENV.APP._2000MS, 'fin-standalone-activation');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Deploy::GoLive::Activation': typeof StandaloneDeployGoLiveActivation;
    'standalone/deploy/go-live/activation': typeof StandaloneDeployGoLiveActivation;
  }
}
