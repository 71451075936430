/* import __COLOCATED_TEMPLATE__ from './go-live.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type RouterService from '@ember/routing/router-service';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type { Step } from 'embercom/components/standalone/common/stepper-item';
import type IntlService from 'embercom/services/intl';
interface Signature {
  Args: {};
}

export default class GoLiveComponent extends Component<Signature> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare router: RouterService;
  @service declare intl: IntlService;

  get steps(): Array<Step> {
    return [
      {
        id: 'trigger-assignment',
        title: this.intl.t('standalone.zendesk.deploy.tickets.go-live.trigger-assignment'),
        button: {
          icon: 'zendesk',
          text: this.intl.t('standalone.zendesk.deploy.tickets.go-live.manage-trigger'),
          disabled: false,
          href: this.router.urlFor('apps.app.standalone.deploy.zendesk.tickets.assign'),
          isExternal: false,
        },
      },
      {
        id: 'training',
        title: this.intl.t('standalone.zendesk.deploy.tickets.go-live.training'),
        button: {
          icon: 'article',
          text: this.intl.t('standalone.zendesk.deploy.tickets.go-live.view-training'),
          href: this.router.urlFor('apps.app.knowledge-hub.overview'),
        },
      },
      {
        id: 'workflow',
        title: this.intl.t('standalone.zendesk.deploy.tickets.go-live.workflow'),
        button: {
          icon: 'workflows',
          text: this.intl.t('standalone.zendesk.deploy.tickets.go-live.view-workflow'),
          href: this.router.urlFor(
            'apps.app.standalone.deploy.zendesk.tickets.workflow.show',
            this.zendeskConfig.ticketsIntegration.workflowRulesetId,
            {
              queryParams: {
                returnPath: this.router.currentRouteName,
              },
            },
          ),
        },
      },
    ];
  }

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig!;
  }

  get isNotConfigured() {
    return this.zendeskConfig.isPendingSetupForTickets;
  }

  @action
  async activate() {
    await this.zendeskConfig.activateForTickets();
  }

  @action
  async deactivate() {
    await this.zendeskConfig.deactivateForTickets();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Deploy::Tickets::GoLive': typeof GoLiveComponent;
  }
}
